import React from "react"

import './../../css/reuseds/intro-anim.css'

const IntroAnim = () => {
  return (
    <div id="introAnim">
      <div className="stars"></div>
      <div className="starlight _1"></div>
      <div className="starlight _2"></div>
      <div className="starlight _3"></div>
      <div className="desk"></div>
      <div className="monitorLight"></div>
      <div className="body">
        <div className="face">
          <div className="eyebrows"></div>
          <div className="eyes"></div>
        </div>
      </div>
    </div>
  )
}

export default IntroAnim 