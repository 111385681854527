import React from "react"
import Layout from "../components/js/layout"
import SEO from "../components/js/seo"

import "../components/css/page/home.css"

import IntroAnim from '../components/js/reuseds/intro-anim.js'
import BookBanner from '../components/js/reuseds/book-banner.js'
import Lectures from '../components/js/reuseds/lectures.js'
import PlayLists from '../components/js/reuseds/playlists.js'


const IndexPage = () => {

  return (
    <Layout page="home">
      <SEO title="얄코 홈" />
      <section id="yalcoHome">
        <section className="intro">
          <div className="words">
            <div>
              <p>가장 <strong>쉬운</strong> 코딩<span>_</span></p>
              <p>가장 <strong>깊숙한</strong> 코딩<span>_</span></p>
              <p>가장 <strong>재미있는</strong> 코딩<span>_</span></p>
            </div>
          </div>
          <div className="anim">
            <IntroAnim></IntroAnim>
          </div>
        </section>

        <section id="books">
          <h1 className="section-title">얄코 저서</h1>
          <BookBanner></BookBanner>
        </section>

        <section id="lectures">
          <h1 className="section-title">얄코 장편강좌들</h1>
          <Lectures></Lectures>
        </section>

        <section id="playlists">
          <h1 className="section-title">얄코 유튜브 영상들</h1>
          <PlayLists></PlayLists> 
        </section>

      </section>
    </Layout>
  )

}

export default IndexPage