import React, { useEffect, useState } from "react"

import './../../css/reuseds/playlists.css'

import youtubeApiData from "./../../../data/youtube-api.yaml"

const UPDATE_EVERY = 1000 * 60 * 60 * 24
const playListOrder = [
  '🕖 시즌 2',
  // '👍 추천 영상',
  '🍿 코딩만화',
]

const PlayLists = () => {

  const [playLists, setPlayLists] = useState([])
  const [movies, setMovies] = useState([])

  const getPlayLists = async () => {
    const lsPlayListsJSON = localStorage.getItem('playLists')
    if (lsPlayListsJSON) {
      const lsPlayLists = JSON.parse(lsPlayListsJSON)
      setPlayLists(lsPlayLists)
      getPlayListItems(lsPlayLists)
      return
    }

    const url = 'https://www.googleapis.com/youtube/v3/playlists'
    const options = {
      ...youtubeApiData,
      part: 'id, snippet, status',
      maxResults: 20
    }

    const res = await fetch (
      `${url}?${new URLSearchParams(options)}`
    ).then((res) => res.json())

    if (!res.items) return

    const playListsFetched = playListOrder.map((pl) => {
        return {
          title: pl,
          ...res?.items?.find((i) =>
            pl.endsWith(i.snippet.title)
          )
        }
      }
    ).filter(r => r)
    .map((r) => {
      return {
        id: r.id,
        title: r.title,
        open: false
      }})

      localStorage.setItem('lastUpdate', new Date().getTime())
      localStorage.setItem('playLists', JSON.stringify(playListsFetched))

      // if (playLists.length > 0) return
      setPlayLists(playListsFetched)
      getPlayListItems(playListsFetched)
  }

  const getPlayListItems = async (pls) => {
    pls.forEach(async (pl) => {
      if (movies.filter((m) => m.playlistId === pl.id).length) return

      const lsPlMvsJSON = localStorage.getItem(`mvs_${pl.id}`)
      if (lsPlMvsJSON) {
        const lsPlMvs = JSON.parse(lsPlMvsJSON)
        setMovies((cur) => [...cur, ...lsPlMvs])
        return
      }

      const url = 'https://www.googleapis.com/youtube/v3/playlistItems'
      const options = {
        ...youtubeApiData,
        playlistId: pl.id,
        part: 'id, snippet, contentDetails, status',
        maxResults: 500,
        order: 'date'
      }
      const res = await fetch (
        `${url}?${new URLSearchParams(options)}`
      ).then((res) => res.json())

      const mvs = res?.items?.map((r) => {
        return {
          playlistId: pl.id,
          id: r?.contentDetails?.videoId,
          title: r?.snippet?.title,
          thumb: r?.snippet?.thumbnails?.high?.url
        }
      }).toReversed()

      if (!mvs) return
      localStorage.setItem(`mvs_${pl.id}`, JSON.stringify(mvs))
      setMovies((cur) => [...cur, ...mvs])
    })
  }

  useEffect(() => {
    const lastUpdate = localStorage.getItem('lastUpdate')
    if (lastUpdate && new Date().getTime() - lastUpdate > UPDATE_EVERY) {
      localStorage.clear()
    }
    getPlayLists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const moviesFiltered = (pl, all) => {
    return movies
    .filter((m) => m.playlistId === pl.id)
    .filter((m) => m.thumb)
    .splice(0, (pl.open || all) ? movies.length : 12)
  }

  const togglePlaylistOpen = (id) => {
    const pl = playLists.find((pl) => pl.id === id)
    pl.open = !pl.open
    setPlayLists([...playLists])
  }

  return (
    <section className="playlists">
      {
        playLists.length > 0
        && playLists.map((pl, key) => (
          <section key={key} className="playlists__pl">
            <h2 className="playlists__title">
              {pl.title}
            </h2>
            <ul className="playlists__list">
              {
                moviesFiltered(pl, false)
                .map((mv, key) => (
                  <li key={key} className="playlists__item">
                    <a
                    href={`https://youtu.be/${mv.id}`}
                    target="_blank"
                    rel="noreferrer"
                    >
                      <div
                      className="playlists__thumb"
                      style={{
                        backgroundImage: `url(${mv.thumb})`
                      }}
                      >
                      </div>
                      <h3 className="playlists__item_title">
                        {mv.title}
                      </h3>
                    </a>
                  </li>
                ))
              }
            </ul>
            {/* <div 
            role="button"
            className="playlists__more"
            onClick={ () => { togglePlaylistOpen(pl.id) } }
            onKeyDown={ () => { togglePlaylistOpen(pl.id) } }
            tabIndex={0}
            >
              {
              pl.open ? '접기'
              : `전체 보기 ( ${moviesFiltered(pl, true).length} ) ▾`
              }
            </div> */}

            
          </section>
        ))
      }

      <div className="playlists__youtube">
        <a href="https://youtube.com/@yalco-coding" target="_blank">
          <button className="to-youtube">
            <span className="material-icons">
              play_circle_filled
            </span> 얄코 유튜브 채널 보러가기
          </button>
        </a>
      </div>

    </section>
  )
}

export default PlayLists 